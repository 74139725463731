var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c('popup-form', {
    attrs: {
      "destroyOnClose": "",
      "maskClosable": false,
      "visible": _vm.visible,
      "title": _vm.title,
      "width": 480,
      "loading": _vm.loading,
      "oktext": "确定"
    },
    on: {
      "cancel": function cancel($event) {
        _vm.visible = false;
      },
      "ok": _vm.submitForm
    }
  }, [_c('div', {
    staticStyle: {
      "font-size": "18px",
      "margin-top": "20px"
    }
  }, [_vm._v(" 设备 "), _c('span', {
    staticStyle: {
      "color": "green"
    }
  }, [_vm._v(_vm._s(_vm.device.deviceSn))]), _vm._v(" 当前" + _vm._s(_vm.device.permissionStatus ? "已授权" : "未授权") + " ")]), _vm.device.permissionStatus ? _c('div', {
    staticStyle: {
      "font-size": "18px",
      "margin-top": "10px"
    }
  }, [_vm._v(" 取消运行授权？ ")]) : _c('div', {
    staticStyle: {
      "font-size": "18px",
      "margin-top": "10px"
    }
  }, [_vm._v(" 授权运行？ ")])]);
};

var staticRenderFns = [];
export { render, staticRenderFns };