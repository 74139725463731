module.exports = {
  messages: {
    CN: {
      input: '请输入',
      select: '请选择',
      name: '单位名称',
      describe: '联系人',
      executor: '联系电话',
      duty: '责任人',
      time: '提醒时间',
      type: '任务类型'
    }
  }
};