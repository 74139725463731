<template>
  <a-form @submit="handleSubmit" :form="form" class="form">
    <a-row class="form-row" type="flex" justify="start" align="top" :gutter="[24, 24]">
      <a-col :sm="{ span: 5 }">
        <a-form-item :label="$t('name')">
          <a-select
            v-decorator="['client.customerId', {rules: [{ required: true, message: $ta('select|name'), whitespace: true}]}]"
            :placeholder="$ta('select|name')"
            show-search
            :options="customer_options"
            @focus="customer_focus"
            @change="customer_change"
          >
          </a-select>
        </a-form-item>
      </a-col>
      <a-col :sm="{ span: 5 }">
        <a-form-item :label="$t('describe')">
          <a-select
            v-decorator="['client.liaisonId', {rules: [{ required: true, message: $ta('select|describe'), whitespace: true}]}]"
            :placeholder="$ta('select|describe')"
            :options="liaison_options"
            @change="liaison_change"
          >
          </a-select>
        </a-form-item>
      </a-col>
      <a-col :sm="{ span: 5 }">
        <a-form-item
          :label="$t('executor')"
        >
          <a-input v-decorator="['client.phone']" disabled />
        </a-form-item>
      </a-col>
    </a-row>
    <a-form-item v-if="showSubmit">
      <a-button htmlType="submit" >Submit</a-button>
    </a-form-item>
  </a-form>
</template>

<script>
import {dataSource as ds} from '@/services'
export default {
  name: 'ClientForm',
  props: ['showSubmit'],
  i18n: require('./i18n-client'),
  data() {
    return {
      form: this.$form.createForm(this),
      customer_options: [],
      liaison_options: [],
    }
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    loadForm(data, device) {
      console.log('clientLF', data, device)
      let editForm = {
        customerId: data.customer?.customerId,
        liaisonId: data.liaison?.liaisonId,
        phone: data.liaison?.phone,
      }
      this.form.setFieldsValue({
        'client.customerId': editForm.customerId,
        'client.liaisonId': editForm.liaisonId,
        'client.phone' : editForm.phone,
      });
      this.customer_focus();
      this.getLiaisonList(editForm.customerId);
    },
    handleSubmit (e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log('Received values of form: ', values)
        }
      })
    },
    customer_focus() {
      if(this.customer_options.length > 0) {
        return;
      }
      ds.getCustomerList().then(res=>{
        console.log('getCustomerList', res)
        if(res.data.meta.success) {
          console.log('dataSource', res.data.data.list)
          this.customer_options = res.data.data.list.list.map(i=>{
            return {
              title: i.customerName,
              value: i.customerId
            }
          })
        }
      })
    },
    customer_change(e) {
      console.log('customer_change: ', e)
      this.form.setFieldsValue({
        'client.liaisonId': undefined,
        'client.phone': undefined
      });
      this.getLiaisonList(e);
    },
    getLiaisonList(id) {
      ds.getCustomerDetail(id).then(res=>{
        console.log('getCustomerDetail', res)
        if(res.data.meta.success) {
          console.log('dataSource', res.data.data.customerDetail)
          this.liaison_options = res.data.data.customerDetail.liaisonList.map(i=>{
            return {
              title: i.liaisonName,
              value: i.liaisonId,
              phone: i.phone
            }
          })
        }
      })
    },
    liaison_change(value ,option)  {
      console.log('liaison_change',value, option)
      this.form.setFieldsValue({
        'client.phone': option.data.props.phone
      });
    },
  }
}
</script>

<style lang="less" scoped>
  .form{
    .form-row{
      margin: 0 -8px
    }
    .ant-col-md-12,
    .ant-col-sm-24,
    .ant-col-lg-6,
    .ant-col-lg-8,
    .ant-col-lg-10,
    .ant-col-xl-8,
    .ant-col-xl-6{
      padding: 0 8px
    }
  }
</style>
