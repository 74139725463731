module.exports = {
  messages: {
    CN: {
      input: '请输入',
      select: '请选择',
      upload: '请上传',
      deviceid: '设备ID',
      name: '设备名称',
      model: '设备型号',
      date: '出厂日期',
      place: '设备地址',
      local: '详细地址',
      pic: '设备照片',
      chuliwu: '处理物类型'
    }
  }
}
