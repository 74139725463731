module.exports = {
  messages: {
    CN: {
      title: '新增设备',
      etitle: '编辑设备',
      desc: '添加设备。',
      info: '设备信息',
      task: '安装信息',
      client: '客户信息',
      back: '返回',
      submit: '提交',
      confirm: '确定',
      sellocal: '选择地址'
    }
  }
};