<template>
  <a-form @submit="handleSubmit" :form="form" class="form">
    <select-local
      ref="newForm"
      :visible="showSelectLocal"
      @cancel="hideSelectLocal"
      @create="selectLocal"
    />
    <a-row
      class="form-row"
      type="flex"
      justify="center"
      align="top"
      :gutter="[20, 20]"
    >
      <a-col :sm="{ span: 5 }">
        <a-form-item :label="$t('deviceid')">
          <a-input
            :placeholder="$ta('input|deviceid')"
            v-decorator="[
              'info.deviceSn',
              {
                rules: [
                  {
                    required: true,
                    message: $ta('input|deviceid'),
                    whitespace: true,
                  },
                ],
              },
            ]"
          />
        </a-form-item>
        <a-form-item :label="$t('date')">
          <a-date-picker
            style="width: 100%"
            format="YYYY年MM月DD日"
            valueFormat="YYYY-MM-DD"
            v-decorator="[
              'info.dateOut',
              { rules: [{ required: true, message: $ta('select|date') }] },
            ]"
          />
        </a-form-item>
      </a-col>
      <a-col :sm="{ span: 5 }">
        <a-form-item :label="$t('name')">
          <a-select
            show-search
            option-label-prop="title"
            :options="dev_options"
            :placeholder="$ta('input|name')"
            v-decorator="[
              'info.deviceModelId',
              {
                rules: [
                  {
                    required: true,
                    message: $ta('input|name'),
                    whitespace: true,
                  },
                ],
              },
            ]"
            @focus="dev_focus"
            @select="dev_select"
          />
        </a-form-item>
        <a-form-item :label="$t('place')">
          <a-input
            :placeholder="$ta('select|place')"
            v-decorator="[
              'info.place',
              {
                rules: [
                  {
                    required: true,
                    message: $ta('input|place'),
                    whitespace: true,
                  },
                  { validator: placevalidate },
                ],
              },
            ]"
            @click="placeClick"
          >
            <a-tooltip slot="suffix" title="地图选点">
              <a-icon
                type="environment"
                style="color: rgba(255,255,255,.85)"
                @click="placeInfoClick"
              />
            </a-tooltip>
          </a-input>
        </a-form-item>
      </a-col>
      <a-col :sm="{ span: 5 }">
        <a-form-item :label="$t('model')">
          <!-- <a-select
            :placeholder="$ta('select|model')"
            v-decorator="['info.model', {rules: [{ required: true, message: $ta('select|model')}]}]"
          >
            <a-select-option value="王同学">王同学</a-select-option>
            <a-select-option value="李同学">李同学</a-select-option>
            <a-select-option value="黄同学">黄同学</a-select-option>
          </a-select> -->
          <a-input disabled v-decorator="['info.model']" />
        </a-form-item>
        <a-form-item :label="$t('local')">
          <a-input
            :placeholder="$ta('input|local')"
            v-decorator="[
              'info.deviceAddress',
              {
                rules: [
                  {
                    required: true,
                    message: $ta('input|local'),
                    whitespace: true,
                  },
                ],
              },
            ]"
          />
        </a-form-item>
      </a-col>
      <a-col :sm="{ span: 5 }">
        <a-form-item :label="$t('chuliwu')">
          <a-input
            :placeholder="$ta('input|chuliwu')"
            v-decorator="[
              'info.processInformation',
              {
                rules: [
                  {
                    required: true,
                    message: $ta('input|chuliwu'),
                    whitespace: true,
                  },
                ],
              },
            ]"
          />
        </a-form-item>
      </a-col>
      <a-col :sm="{ span: 4 }">
        <a-form-item :label="$t('pic')" class="img_upload">
          <div class="clearfix ">
            <a-upload
              :action="SAVEMONFILE"
              list-type="picture-card"
              accept="image/png, image/jpeg"
              name="uploadFile"
              :disabled="uploadDisabled"
              :file-list="fileList"
              @preview="handlePreview"
              @change="handleChange"
              :remove="beforeRemove"
              :beforeUpload="beforeUpload"
              v-decorator="['info.pic', { rules: [{ validator: validate }] }]"
            >
              <div v-if="fileList.length < 3">
                <a-icon type="plus" />
                <div class="ant-upload-text">
                  Upload
                </div>
              </div>
            </a-upload>
            <a-modal
              class="modal_viewer_in_info"
              :visible="previewVisible"
              :footer="null"
              @cancel="handleCancel"
            >
              <img alt="example" style="width: 100%" :src="previewImage" />
            </a-modal>
          </div>
        </a-form-item>
      </a-col>
    </a-row>
    <a-form-item v-if="showSubmit">
      <a-button htmlType="submit">Submit</a-button>
    </a-form-item>
  </a-form>
</template>

<script>
import { OSSSAVEMONFILE } from "@/services/api";
import SelectLocal from "./SelectLocal";
import { dataSource as ds } from "@/services";
// import { GETMONFILE } from "@/services/api";
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
export default {
  name: "InfoForm",
  props: ["showSubmit"],
  components: { SelectLocal },
  i18n: require("./i18n-infoForm"),
  data() {
    return {
      // GETMONFILE,
      form: this.$form.createForm(this),
      editMode: false,
      SAVEMONFILE: OSSSAVEMONFILE,
      previewVisible: false,
      showSelectLocal: false,
      previewImage: "",
      fileList: [],
      street: "",
      place: "",
      point: undefined,
      local: undefined,
      dev_options: [],
      uploadDisabled: false,
      editForm: undefined,
    };
  },
  methods: {
    loadForm(data, device) {
      console.log("infoLF", data, device);
      this.editMode = true;
      let editForm = {
        deviceSn: data.deviceSn,
        dateOut: device.dateOut,
        deviceModelId: device?.deviceModel?.deviceModelId,
        deviceAddress: data.deviceAddress,
        model: data.deviceModel?.deviceModelType,
        address: data.address,
        processInformation: data.processInformation,
      };
      this.editForm = editForm;
      this.form.setFieldsValue({
        "info.deviceSn": editForm.deviceSn,
        "info.dateOut": editForm.dateOut,
        "info.deviceModelId": editForm.deviceModelId,
        "info.model": editForm.model,
        "info.deviceAddress": editForm.deviceAddress,
        "info.processInformation": editForm.processInformation,
        "info.place": editForm.address,
      });
      this.dev_focus();
      this.point = {
        lat: device.lat,
        lng: device.lon,
      };
      this.local = [data.province, data.city, data.town];

      console.log("infoLF load Img", device.imgs);
      if (device.imgs.length != 0) {
        device.imgs.forEach((e) => {
          // let url = this.GETMONFILE + "/" + e;
          let url = e.path ? e.path: '';
          console.log("deviceImg", device.imgs, url);
          if(e.path === undefined) return;
          this.fileList.push({
            uid: e.picId,
            name: "image.png",
            status: "done",
            url: url,
            fileid: e.picId,
            fileob: e,
          });
        });
        // this.form.setFieldsValue({
        //   'info.pic' : {
        //     fileList: this.fileList
        //   },
        // })
      } else {
        //this.deviceimg = ''
      }
      console.log("infoLF loadForm complete");
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("Received values of form: ", values);
        }
      });
    },
    placevalidate(rule, value, f) {
      console.log("placevalidate", value);
      if (this.point === undefined) {
        f("请用地图选点");
      }
      f();
    },
    validate(rule, value, f) {
      console.log("validatefileList", value);
      // console.log('validatefileList', this.fileList)
      // if(this.fileList.length !== 0) {
      //   f('请上传设备照片')
      // }
      // if(value === undefined) {
      //   f('(点击上传)')
      // }
      if (this.fileList.length === 0) {
        f("请上传设备照片");
      }
      if (this.fileList.length > 3) {
        f("只能上传最多3张照片，请删除多余照片");
      }
      if (this.fileList.some((i) => i.status === "error")) {
        f("请重新上传出错的照片");
      }
      f();
    },
    handleCancel() {
      this.previewVisible = false;
    },
    beforeRemove() {
      //file) {
      // console.log('beforeRemove: ', file)
      // return false;
      return true;
    },
    beforeUpload() {
      return true;
    },
    dev_focus() {
      if (this.dev_options.length > 0) {
        return;
      }
      ds.deviceModelList().then((res) => {
        console.log("deviceModelList", res);
        if (res.data.meta.success) {
          console.log("dataSource", res.data.data.list);
          this.dev_options = res.data.data.list.list.map((i) => {
            return {
              title: i.deviceModelName,
              value: i.deviceModelId,
              id: i.deviceModelType,
              label: i.deviceModelName,
            };
          });
        }
      });
    },
    dev_select(value, option) {
      // console.log('dev_select',value, option)
      this.form.setFieldsValue({
        "info.model": option.data.props.id,
      });
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleChange(param) {
      this.fileList = param.fileList;
      // console.log('handleChange', param)
      this.$emit("Change", this.fileList);
    },
    placeClick() {
      let info_place = this.form.getFieldsValue(["info.place"]);
      console.log(info_place);
      if (info_place.info?.place === undefined) this.showSelectLocal = true;
    },
    placeInfoClick() {
      this.showSelectLocal = true;
      if(this.editMode) {
        this.$refs.newForm.loadData(this.point,this.local, this.editForm)
      }
    },
    selectLocal(e) {
      this.showSelectLocal = false;
      console.log(e);
      this.form.setFieldsValue({
        "info.place": e.place,
      });
      this.form.setFieldsValue({
        "info.deviceAddress": e.street,
      });
      this.point = e.point;
      this.local = e.local;
    },
    hideSelectLocal() {
      this.showSelectLocal = false;
    },
    getPoint() {
      let point = {
        lon: String(this.point.lng),
        lat: String(this.point.lat),
        province: this.local[0],
        city: this.local[1],
        town: this.local[2],
      };
      // console.log('getPoint', point)
      return point;
    },
    getImg() {
      console.log('getImg', this.fileList);
      let imgs = this.fileList.map((f) => {
        if (f.fileid) {
          return f.fileob;
        } else {
          return {
            picId: '',
            path: f.response.data.filePath
          }
        }
      });
      return imgs;
    },
  },
};
</script>

<style lang="less" scoped>
.form {
  .form-row {
    margin: 0 -8px;
  }
  .ant-col-md-12,
  .ant-col-sm-24,
  .ant-col-lg-6,
  .ant-col-lg-8,
  .ant-col-lg-10,
  .ant-col-xl-8,
  .ant-col-xl-6 {
    padding: 0 8px;
  }
  .img_upload {
    margin-bottom: 0;
    :deep(.ant-upload-list-picture-card-container) {
      width: 80px;
      height: 80px;
    }

    :deep(.ant-upload-list-picture-card .ant-upload-list-item) {
      width: 80px;
      height: 80px;
    }
    :deep(.ant-upload.ant-upload-select-picture-card) {
      width: 80px;
      height: 80px;
    }
  }
}
</style>

<style lang="less">
/* you can make up upload button and sample style by using stylesheets */
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.modal_viewer_in_info {
  .ant-modal-content {
    background: none;
  }
}
</style>
