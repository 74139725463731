import _objectSpread from "G:/worker/DeviceCloud/vue-antd-admin/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
// eslint-disable-next-line no-unused-vars
import { dataSource as ds } from "@/services";
import { mapGetters } from "vuex";
import PopupForm from "./PopupForm.vue";
export default {
  components: {
    PopupForm: PopupForm
  },
  name: "changepermission",
  props: {
    title: {
      type: String,
      default: "更改授权"
    },
    formdata: {
      type: Object,
      default: function _default() {
        return {
          password: "",
          newpassword: "",
          newpasswordrepeat: ""
        };
      }
    }
  },
  data: function data() {
    return {
      visible: false,
      loading: false,
      device: {
        deviceSn: ''
      }
    };
  },
  computed: _objectSpread({}, mapGetters("account", ["user"])),
  beforeCreate: function beforeCreate() {
    this.form = this.$form.createForm(this);
  },
  methods: {
    submitForm: function submitForm() {
      var _this = this;

      this.loading = true;
      ds.setPermission({
        deviceId: this.device.deviceId,
        permissionStatus: this.device.permissionStatus ? 0 : 1
      }).then(function (res) {
        if (res.data.meta.success) {
          _this.$message.success("修改成功");

          setTimeout(function () {
            _this.visible = false;

            _this.$emit("create");
          }, 200);
        } else {
          _this.$message.error("修改失败： " + res.data.meta.msg);
        }
      }).catch(function (err) {
        _this.$message.error("修改失败：网络错误");
      }).finally(function () {
        _this.loading = false;
      });
    },
    show: function show(device) {
      this.visible = true;
      this.device = device;
    }
  }
};