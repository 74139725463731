<template>
  <a-form @submit="handleSubmit" :form="form" class="form">
    <a-row class="form-row" type="flex" justify="start" align="top" :gutter="[24, 24]">
      <a-col :sm="{ span: 5 }">
        <a-form-item :label="$t('name')">
          <a-date-picker class="class_date_picker" :format="dateFormat" 
            valueFormat="YYYY-MM-DD"
            v-decorator="['task.dateInstall', {rules: [{ required: true, message: $ta('select|name|time')}]}]"
          />
        </a-form-item>
      </a-col>
      <a-col :sm="{ span: 5 }">
        <a-form-item :label="$t('describe')">
          <a-date-picker class="class_date_picker" :format="dateFormat" 
            valueFormat="YYYY-MM-DD"
            v-decorator="['task.dateAccept', {rules: [{ required: true, message: $ta('select|describe|time')}]}]"
          />
        </a-form-item>
      </a-col>
      <a-col :sm="{ span: 5 }">
        <a-form-item
          :label="$t('executor')"
        >
          <a-date-picker class="class_date_picker" :format="dateFormat" 
            valueFormat="YYYY-MM-DD"
            v-decorator="['task.dateQuality', {rules: [{ required: true, message: $ta('select|executor|time')}]}]"
          />
        </a-form-item>
      </a-col>
    </a-row>
    <a-form-item v-if="showSubmit">
      <a-button htmlType="submit" >Submit</a-button>
    </a-form-item>
  </a-form>
</template>

<script>
const dateFormat = 'YYYY年MM月DD日';
import moment from 'moment';
export default {
  name: 'TaskForm',
  props: ['showSubmit'],
  i18n: require('./i18n-task'),
  data() {
    return {
      form: this.$form.createForm(this),
      dateFormat,
      defaultdate:moment('2015/01/01', dateFormat),
    }
  },
  methods: {
    moment,
    loadForm(data, device) {
      // console.log('taskLF', data, device)
      let editForm = {
        dateInstall: device.dateInstall,
        dateAccept: device.dateAccept,
        dateQuality: device.dateQuality,
      }
      this.form.setFieldsValue({
        'task.dateInstall': editForm.dateInstall,
        'task.dateAccept': editForm.dateAccept,
        'task.dateQuality' : editForm.dateQuality,
      });
    },
    handleSubmit (e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log('Received values of form: ', values)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .form{
    .form-row{
      margin: 0 -8px
    }
    .ant-col-md-12,
    .ant-col-sm-24,
    .ant-col-lg-6,
    .ant-col-lg-8,
    .ant-col-lg-10,
    .ant-col-xl-8,
    .ant-col-xl-6{
      padding: 0 8px
    }
  }
  .class_date_picker {
    width: 100%;
  }
</style>
