<template>
  <popup-form class="class_newuser" :visible="visible" :width="1600" :maskClosable="false"
    :title="edit?$t('etitle'):$t('title')"
    destroyOnClose
    @cancel="onCancel"
    @ok="submitForm"
  >
    <a-row type="flex" justify="center" align="top">
      <a-col :sm="24">
          <a-divider orientation="left">
            {{$t('info')}}
          </a-divider>
          <info-form ref="info" :showSubmit="false" />
          <a-divider orientation="left">
            {{$t('client')}}
          </a-divider>
          <client-form ref="client" :showSubmit="false" />
          <a-divider orientation="left">
            {{$t('task')}}
          </a-divider>
          <task-form ref="task" :showSubmit="false" />
        <!-- <a-card class="card" :bordered="false">
          <a-button type="primary" @click="validate" :loading="loading">{{$t('submit')}}</a-button>
          <a-button @click="navback" class="ml-30">{{$t('back')}}</a-button>
        </a-card> -->
      </a-col>
    </a-row>
    <!-- <footer-tool-bar>
      <a-button type="primary" @click="validate" :loading="loading">{{$t('submit')}}</a-button>
    </footer-tool-bar> -->
  </popup-form>
</template>

<script>
import InfoForm from './InfoForm'
import TaskForm from './TaskForm'
import ClientForm from './ClientForm'
import FooterToolBar from '@/components/tool/FooterToolBar'
import PopupForm from "@/pages/components/PopupForm"
import {dataSource as ds} from '@/services'
export default {
  name: 'AdvancedForm',
  props: ["visible","edit"],
  // eslint-disable-next-line vue/no-unused-components
  components: {FooterToolBar, ClientForm, TaskForm, InfoForm, PopupForm},
  i18n: require('./i18n'),
  data () {
    return {
      loading: false,
      deviceimg: '',
      deviceId: undefined,
    }
  },
  computed: {
    desc() {
      return this.$t('desc')
    }
  },
  mounted() {
  },
  methods: {
    loadForm(data) {
      console.log('loadForm', data)
      this.deviceId = data.deviceId
      ds.getDevice(data.deviceId).then(res=>{
        console.log('getDevice', res)
        if(res.data.meta.success) {
          this.$refs.info.loadForm(data, res.data.data.device)
          this.$refs.client.loadForm(data, res.data.data.device)
          this.$refs.task.loadForm(data, res.data.data.device)
        }
      })
    },
    async validate () {
      // this.$refs.info.form.validateFields((err, values) => {
      //   if (!err) {
      //     console.log('Received values of form: ', values)
      //   }
      // })
      // this.$refs.task.form.validateFields((err, values) => {
      //   if (!err) {
      //     console.log('Received values of form: ', values)
      //   }
      // })
      // this.$refs.client.form.validateFields((err, values) => {
      //   if (!err) {
      //     console.log('Received values of form: ', values)
      //   }
      // })
      let ret = {}
      ret = {...ret, ...await this.validateForm('info')}
      ret = {...ret, ...await this.validateForm('task')}
      ret = {...ret, ...await this.validateForm('client')}
      console.log('validate', ret)
      return ret
    },
    validateForm(name) {
      return new Promise((resolve,reject)=>{
        this.$refs[name].form.validateFields((err, values) => {
          if (err) {
            reject(err)
          } else {
            resolve(values)
          }
        })
      })
    },
    onCancel() {
      this.clearForm()
      this.$emit('cancel');
    },
    clearForm() {
      this.$refs.info.form.resetFields();
      this.$refs.task.form.resetFields();
      this.$refs.client.form.resetFields();
    },
    picToimgs(obj) {
      // let arr = obj.pic.fileList.map(f=>{
      //   return f.response.data.fileid;
      // })
      // obj.imgs = arr
      delete obj.pic
    },
    async submitForm () {
      let error = false
      let ret = null
      try {
        ret = await this.validate()
        ret = {...ret.info, ...ret.task, ...ret.client}
        let local = this.$refs.info.getPoint()
        ret = {...ret, ...local}
        let imgs = this.$refs.info.getImg()
        this.picToimgs(ret)
        ret = {...ret, imgs: imgs}
        console.log('submitForm', ret)
      } catch (err) {
        console.log('submitFormERR', err)
        this.$message.warn('请按提示完善表单')
        return false
      }
      
      console.log('submitForm po')
      try {
        let res = null
        if (this.edit) {
          ret = {deviceId: this.deviceId , ...ret}
          console.log('editDevice', ret)
          res = await ds.editDevice(ret)
          console.log('editDevice', res)
        } else {
          res = await ds.addDevice(ret)
          console.log('addDevice', res)
        }
        if(res.data.meta.success) {
          this.$message.info(res.data.meta.msg)
          this.$emit('create')
        } else {
          this.$message.error(res.data.meta.msg)
          return false
        }
      } catch (err) {
        console.log('submitFormERR', err)
        this.$message.error('提交表单发生错误')
        return false
      }

      return error
    },
  }
}
</script>

<style lang="less" scoped>
  .card{
    margin-bottom: 6px;
  }
  .ml-30 {
    margin-left: 30px;
  }
</style>
