var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c('div', {
    staticClass: "toolbar"
  }, [_c('div', {
    staticStyle: {
      "float": "left"
    }
  }, [_vm._t("extra")], 2), _c('div', {
    staticStyle: {
      "float": "right"
    }
  }, [_vm._t("default")], 2)]);
};

var staticRenderFns = [];
export { render, staticRenderFns };