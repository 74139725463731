import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.object.to-string.js";
import RegionSelect from "@/pages/components/RegionSelect";
import PopupForm from "@/pages/components/PopupForm";
export default {
  name: "SelectLocal",
  props: ["visible"],
  components: {
    PopupForm: PopupForm,
    RegionSelect: RegionSelect
  },
  i18n: require("./i18n"),
  data: function data() {
    return {
      loading: false,
      city: "",
      province: "",
      district: "",
      // 地图你解析方法实例
      myGeo: null,
      // 已选坐标，呈现mark用
      point: {
        lng: 116.404,
        lat: 39.915
      },
      // 搜索关键字
      local: undefined,
      keyword: "",
      slocal: "",
      street: "",
      place: "",
      searchDisabled: true,
      // 初始化地图中心点
      center: null,
      editMode: false
    };
  },
  computed: {
    spoint: function spoint() {
      return "[ ".concat(this.point.lng, ", ").concat(this.point.lat, " ]");
    }
  },
  methods: {
    loadData: function loadData(point, local, editForm) {
      this.editMode = true;
      this.local = local;
      this.point = point;
      this.place = editForm.address;
      this.street = editForm.deviceAddress;
    },
    // eslint-disable-next-line no-unused-vars
    selectPoint: function selectPoint(_ref) {
      var type = _ref.type,
          target = _ref.target,
          point = _ref.point,
          pixel = _ref.pixel,
          overlay = _ref.overlay;
      this.point = point;

      var _this = this; // 根据坐标逆解析获取地址详细描述


      this.myGeo.getLocation(point, function (result) {
        if (result) {
          _this.city = result.addressComponents.city;
          _this.province = result.addressComponents.province;
          _this.district = result.addressComponents.district;
          _this.slocal = result.surroundingPois.length > 0 ? result.surroundingPois[0].address + result.surroundingPois[0].title : result.address;
          _this.place = _this.province + _this.city + _this.district;
          _this.street = result.addressComponents.street + result.addressComponents.streetNumber;
        } // console.log("result", result);

      });
    },
    // eslint-disable-next-line no-unused-vars
    mapReady: function mapReady(_ref2) {
      var BMap = _ref2.BMap,
          map = _ref2.map;

      var _this = this; // 获取自动定位方法


      var geolocation = new BMap.Geolocation(); // 获取逆解析方法实例

      this.myGeo = new BMap.Geocoder(); // 获取自动定位获取的坐标信息

      if (this.editMode) {
        var point = new BMap.Point(parseFloat(_this.point.lng), parseFloat(_this.point.lat));
        map.centerAndZoom(point, 13);
      } else {
        geolocation.getCurrentPosition(function (r) {
          _this.center = {
            lng: r.point.lng,
            lat: r.point.lat
          };
          _this.point = {
            lng: r.point.lng,
            lat: r.point.lat
          };
        }, {
          enableHighAccuracy: true
        });
      }
    },
    clickEvent: function clickEvent(e) {},
    localChange: function localChange(e, selectedOptions) {
      // console.log(e, selectedOptions)
      this.local = e;
      var tmp = selectedOptions.reduce(function (acc, cur) {
        return {
          label: acc.label + cur.label
        };
      }); // console.log('selectedOptions', tmp.label)

      this.keyword = tmp.label;
      this.searchDisabled = false;
    },
    infohtmlset: function infohtmlset(e) {
      if (e) {
        this.city = e.city;
        this.province = e.province;
        this.district = e.district;
        this.point = e.point;
        this.slocal = e.address;
        this.place = this.province + this.city;
        this.street = e.title;
      }
    },
    search: function search() {},
    onCancel: function onCancel() {
      this.$emit("cancel");
    },
    submitForm: function submitForm() {
      if (this.local.length < 3) {
        this.$message.warn('请选择省市区');
        return false;
      }

      this.$emit("create", {
        local: this.local,
        point: this.point,
        place: this.place,
        street: this.street
      });
    }
  }
};