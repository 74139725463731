module.exports = {
  messages: {
    CN: {
      input: '请输入',
      select: '请选择',
      name: '安装时间',
      describe: '验收时间',
      executor: '质保到期',
      duty: '责任人',
      time: '时间',
      type: '任务类型'
    }
  }
};